<template>
  <div>
    <VTitle :title="$t('app.licenses', 2)" class="route-title" />

    <div>
      <div class="flex justify-between flex-col sm:flex-row items-center">
        <VTitle :title="getAttribute(invoice, 'name')" class="section-title" />

        <div class="w-full sm:w-auto flex sm:mt-0">
          <VButton
            class="btn-primary"
            icon="fa-download"
            @click="onClickDownload"
          />
        </div>
      </div>

      <div class="box overflow-hidden" :class="{ 'intro-y': hasAnimation }">
        <div
          class="border-b border-gray-200 dark:border-dark-5 text-center sm:text-left"
        >
          <div class="flex justify-between px-5 py-10 sm:px-20 sm:py-20">
            <div>
              <div
                class="text-theme-1 dark:text-theme-10 font-semibold text-5xl uppercase"
              >
                {{ $t("app.invoices", 1) }}
              </div>

              <div class="mt-1">
                {{ $t("app.licenses", 2) }} #{{ getAttribute(data, "number") }}
              </div>

              <div class="mt-1">
                {{ date }}
              </div>
            </div>

            <div class="grid grid-cols-1 place-items-end">
              <div class="text-2xl font-bold text-theme-1">
                {{ getAttribute(data, "company.name") }}
              </div>

              <div class="mt-1">
                {{ getAttribute(data, "company.email") }}
              </div>

              <div class="mt-1">
                {{ getAttribute(data, "company.address.line_1") }}
              </div>

              <div class="mt-1">
                {{ getAttribute(data, "company.address.line_2") }}
              </div>

              <div class="mt-1">
                {{ getAttribute(data, "company.address.line_3") }}
              </div>
            </div>
          </div>

          <div
            class="flex flex-col lg:flex-row px-5 sm:px-20 pt-10 pb-10 sm:pb-20"
          >
            <div>
              <div class="text-base text-gray-600">
                {{ $t("app.client_details") }}
              </div>

              <div
                class="text-2xl font-medium text-theme-1 dark:text-theme-10 mt-2"
              >
                {{ getAttribute(data, "client.name") }}
              </div>

              <div class="mt-1">
                {{ getAttribute(data, "client.email") }}
              </div>

              <div class="mt-1">
                {{ getAttribute(data, "client.address.line_1") }}
              </div>

              <div class="mt-1">
                {{ getAttribute(data, "client.address.line_2") }}
              </div>

              <div class="mt-1">
                {{ getAttribute(data, "client.address.line_3") }}
              </div>
            </div>

            <div class="lg:text-right mt-10 lg:mt-0 lg:ml-auto">
              <div class="text-base text-gray-600">
                {{ $t("app.purchase_order") }}
              </div>

              <div
                class="text-2xl font-medium text-theme-1 dark:text-theme-10 mt-2"
              >
                {{ getAttribute(data, "purchase_order") }}
              </div>

              <div class="mt-1">
                {{ $t("app.invoices", 1) }}
                {{ getAttribute(invoice, "sequence") }}
              </div>

              <div class="mt-1">
                {{ getAttribute(currency, "name") }}
              </div>
            </div>
          </div>
        </div>

        <div class="px-5 sm:px-16 py-10 sm:py-20">
          <div class="overflow-x-auto">
            <table class="table">
              <thead>
                <tr>
                  <th
                    class="border-b-2 dark:border-dark-5 whitespace-nowrap uppercase"
                  >
                    {{ $t("app.description") }}
                  </th>

                  <th
                    class="border-b-2 dark:border-dark-5 text-right whitespace-nowrap uppercase"
                  >
                    {{ $t("app.qty") }}
                  </th>

                  <th
                    class="border-b-2 dark:border-dark-5 text-right whitespace-nowrap uppercase"
                  >
                    {{ $t("app.price") }}
                  </th>

                  <th
                    class="border-b-2 dark:border-dark-5 text-right whitespace-nowrap uppercase"
                  >
                    {{ $t("app.subtotal") }}
                  </th>
                </tr>
              </thead>

              <!-- BEGIN: items -->
              <tbody>
                <tr v-for="(item, index) in items" :key="index">
                  <td class="border-b dark:border-dark-5">
                    <div class="font-medium whitespace-nowrap">
                      {{ item.name }}
                    </div>

                    <div class="text-gray-600 text-sm mt-0.5 whitespace-nowrap">
                      {{ item.description }}
                    </div>
                  </td>

                  <td class="text-right border-b dark:border-dark-5 w-32">
                    {{ formatAmount(item.quantity, 0) }}
                  </td>

                  <td class="text-right border-b dark:border-dark-5 w-32">
                    {{ currencySymbol }} {{ formatAmount(item.unit_price, 0) }}
                  </td>

                  <td
                    class="text-right border-b dark:border-dark-5 w-32 font-medium"
                  >
                    {{ currencySymbol }} {{ formatAmount(item.subtotal) }}
                  </td>
                </tr>

                <tr v-for="(tax, index) in taxes" :key="`tax-${index}`">
                  <td
                    colspan="2"
                    class="text-right border-b dark:border-dark-5 w-32"
                  >
                    <span class="font-medium">
                      {{ getAttribute(tax, "name") }}
                    </span>

                    <span> ({{ getAttribute(tax, "number") }})</span>
                  </td>

                  <td class="text-right border-b dark:border-dark-5 w-32">
                    {{ getAttribute(tax, "percentage") }} %
                  </td>

                  <td class="text-right border-b dark:border-dark-5 w-32">
                    {{ currencySymbol }}
                    {{ formatAmount(getAttribute(tax, "amount")) }}
                  </td>
                </tr>
              </tbody>
              <!-- END: items -->
            </table>
          </div>
        </div>

        <div
          class="px-5 sm:px-20 pb-10 sm:pb-20 flex flex-col-reverse sm:flex-row"
        >
          <!-- BEGIN: payment info -->
          <div class="text-center sm:text-left mt-10 sm:mt-0">
            <div class="text-base text-gray-600">
              {{ $t("app.payment_info") }}
            </div>
            <div
              class="text-lg text-theme-1 dark:text-theme-10 font-medium mt-2"
            >
              {{ getAttribute(data, "payment_info.payee") }}
            </div>

            <div class="mt-1">
              {{ $t("app.bank_account") }}:
              {{ getAttribute(data, "payment_info.bank_account") }}
            </div>

            <div class="mt-1">
              {{ $t("app.terms") }}:
              {{ getAttribute(data, "payment_info.terms") }}
            </div>
          </div>
          <!-- END: payment info -->

          <div class="text-center sm:text-right sm:ml-auto">
            <div class="text-base text-gray-600">
              {{ $t("app.total_amount") }}
            </div>

            <div
              class="text-4xl text-theme-1 dark:text-theme-10 font-medium mt-3"
            >
              {{ currencySymbol }}
              {{ formatAmount(getAttribute(data, "total_amount")) }}
            </div>

            <div class="mt-3 text-xs">
              {{ $t("app.taxes_included") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Composables
import useDisplay from "@/composables/useDisplay";
import useAnimation from "@/composables/useAnimation";
import useDownload from "@/composables/useDownload";
// Components
import VTitle from "@/components/VTitle";
import VButton from "@/components/VButton";

export default {
  components: {
    VTitle,
    VButton
  },
  props: {
    licenseId: {
      type: String,
      required: true
    },
    invoiceId: {
      type: String,
      required: true
    }
  },
  setup() {
    // Composables
    const { download } = useDownload();
    const { formatAmount, formatDate } = useDisplay();
    const { hasAnimation } = useAnimation();

    return {
      download,
      formatAmount,
      formatDate,
      hasAnimation
    };
  },
  data() {
    return {
      invoice: {}
    };
  },
  computed: {
    documentTitle() {
      return `${this.$t("app.invoices", 1)} - ${this.$t(
        "app.licenses",
        2
      )} - ${this.$t("app.administration")}`;
    },
    status() {
      return this.getAttribute(this.invoice, "status");
    },
    data() {
      if (!this.invoice?.data) {
        return {};
      }

      const data = this.getAttribute(this.invoice, "data", {});
      return JSON.parse(data);
    },
    date() {
      const value = this.getAttribute(this.data, "date");
      return this.formatDate({ date: value });
    },
    currency() {
      return this.getAttribute(this.data, "currency");
    },
    currencySymbol() {
      return this.getAttribute(this.currency, "symbol");
    },
    items() {
      return this.getAttribute(this.data, "items", []);
    },
    taxes() {
      return this.getAttribute(this.data, "sales_taxes", []);
    }
  },
  async created() {
    await this.getData();
  },
  methods: {
    getAttribute(obj, attr, fallback = "") {
      return this.$_.get(obj, attr, fallback);
    },
    async getData() {
      const response = await this.$store.dispatch("api/request", {
        endpoint: "administration.licenses.invoice",
        pathParams: {
          id: this.licenseId,
          invoice_id: this.invoiceId
        }
      });

      this.invoice = response?.payload?.data || {};
    },
    onClickDownload() {
      this.download("administration.licenses.invoice", {
        id: this.licenseId,
        invoice_id: this.invoiceId
      });
    }
  }
};
</script>
